(function() {
  'use strict';

  /* @ngdoc object
   * @name sliceApp
   * @description
   *
   */
  angular
    .module('sliceApp', [
      'ngMaterial',
      'ui.router',
      'home',
      'login',
      'menu',
      'myOrder',
      'myPizza',
      'combo',
      'category',
      'noConnection',
      'google.places',
      // 'mdPickers',
      'ngMaterialDatePicker',
      'settings'
    ]).constant("consts", {
      //serverUrl: 'http://sliceApi/',
      serverUrl: 'http://10.0.0.4:5131/',
      franchiseId: 1,
      tranzilaUsername: "ttxbitetok"
    })
    .factory('errorInterceptor', ['$q', '$rootScope', '$location', 'consts', function($q, $rootScope, $location, consts) {
      return {
        responseError: function(response) {
          //     if (consts.serverUrl != "/")
          //     {
          if (response && response.status === 401) {
            localStorage.clear();
            hideLoader();
            $location.path('login')
          }
          //No internet
          if (response && (response.status === -1 || response.status === 404)) {
            hideLoader();
            if ($location.url() != "/no-connection") {
              $location.path('no-connection')
            }
          }
          //   }
          return $q.reject(response);
        }
      };
    }])
    // .value('scDateTimeConfig', {
    //   // defaultTheme: 'material',
    //   // autosave: false,
    //    defaultMode: 'time',
    //   // defaultDate: undefined, //should be date object!!
    //    displayMode: 'time',
    //    displayTwentyfour: true,
    //   // defaultOrientation: false,
    //   // compact: false
    // })
    .config([
      '$compileProvider', '$provide', '$httpProvider',
      function($compileProvider, $provide, $httpProvider) {
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|geo|tel):/);
        $httpProvider.interceptors.push('errorInterceptor');
      }
    ]);
}());


var showLoader = function() {
  $("#loader").show();
}

var hideLoader = function() {
  $("#loader").hide();
}
